import React from 'react';
import { Footer } from '../containers';
import { Navbar } from '.';
import './Bio.css';
import zgornjaslika from '../assets/bio/lift-2_obrezana.jpg';
import spodnjadesnaslika from '../assets/bio/31_obrezana.jpg';
import spodnjalevaslika from '../assets/bio/IMG_1565_obrezana.jpg';


const Bio = () => {
    return (
        <div className="App">
          <div className="">
            <Navbar />
          </div>
          <div className='bio-naslov-sirina'>
          <h1 className='bio-naslov'>BIO</h1>
          </div>
          <div className='bio-container'>
            <div className='levi-bio-container'>
            
            <p className='bio-opis'>
            I'm a 28 years old videographer from Cerknica, Slovenia. I've been in love with video production since I was 7 years old, when my dad showed me how to make simple animation with some old digital camera. I was stoked. About 3 years later I bought my first camcorder and fell in love with it. Since then I've always been shooting and editing all kinds of videos. In high school I did my first paid music video and figured out that this is what I want to do for a living. After completing high school I went on to graduate at the IAM College of multimedia in Ljubljana, where I majored in video production.

Video production has become my job 3 years ago and I couldn't be happier about it.

I'm a fan of all kinds of media, so I really enjoy playing drums and watching exiting movies, but my biggest passion is football, of course!

One of my first wishes when I was just a young boy was to shoot my own feature film or series.

I've been working very diligently on this wish for the last few years. My passion project, my first comedy series, which will be released in 2024. My key responsibilities revolve around producing, directing, writing and editing.
            </p>
            </div>
            <div className='desni-bio-container'>
                <div className='desni-container-zgoraj'>
                    <img className='zgornja-slika' src={zgornjaslika} alt='V dvigalu'></img>
                </div>
                <div className='spodnji-sliki'>
                    <img className='spodnja-slika-leva' src={spodnjalevaslika} alt='V dvigalu'></img>
                    <img className='spodnja-slika-desna' src={spodnjadesnaslika} alt='V dvigalu'></img>
                </div>
            </div>
          </div>
          
          <Footer />
        </div>
      );
};

export default Bio;