import React from 'react'
import './Header.css'


const Header = () => {
  return (
    <div>
      <div className='sirina-teksta'>
      <h1 className='jan-stefancic'>JAN ŠTEFANČIČ</h1>
        <h3 className='director'>PROMOS | EVENT VIDEOS | MUSIC VIDEOS | COMMERCIALS  &  MORE</h3>
      </div>
      
    </div>
  )
}

export default Header