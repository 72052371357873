import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Footer } from '../containers';
import { Navbar } from '.';
import '../App.css';
import './Contact.css';
import emailjs, { init } from 'emailjs-com';


init('HT5USrbnGFq9KdrIk');

const Contact = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = data => {
    console.log(data);

    setIsSubmitting(true);
    toast.info("Sending email, please wait...");

    emailjs.send('poslji_mail', 'template_8ne2onh', data, 'HT5USrbnGFq9KdrIk')
      .then(response => {
        console.log(response);
        toast.success("Email sent successfully!");
      })
      .catch(error => {
        console.log(error);
        toast.error("There was an error sending your message. Please try again later.");
        setIsSubmitting(false);
      });
  }


  
  return (
    <div className="App">
      <div className="">
        <Navbar />
      </div>
      <div className='sirina-contact'>
        <h1 className='kontakt-naslov'>GET IN TOUCH</h1>
          <div className="contactForm">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="inputRow">
              <div className="inputField">
                <p className='kontakt-txt'>FIRST NAME</p>
                <input {...register('firstName', { required: false })} placeholder="" />
                {errors.firstName && <span className="errorMsg">This field is required</span>}
              </div>
              
              <div className="inputField">
              <p className='kontakt-txt'>LAST NAME</p>
                <input {...register('lastName', { required: false })} placeholder="" />
                {errors.lastName && <span className="errorMsg">This field is required</span>}
              </div>

              <div className="inputField">
              <p className='kontakt-txt'>EMAIL*</p>
                <input {...register('email', { required: true })} placeholder="" />
                {errors.email && <span className="errorMsg">This field is required</span>}
              </div>
            </div>

            <div className="inputRow">
              <div className="inputField">
              <p className='kontakt-txt'>LEAVE ME A MESSAGE...</p>
                <textarea {...register('message', { required: true })} placeholder="" />
                {errors.message && <span className="errorMsg">This field is required</span>}
              </div>
            </div>
            
            <div className="inputButton">
              <input type="submit" className="submitBtn" value="SUBMIT" disabled={isSubmitting}/>
            </div>
          </form>
          
{/*           <a className='link-mail-telefon kontakt-telefon'href='tel:+38640381313'>+386 40 381 313</a>
          <a className='link-mail-telefon kontakt-mail' href='mailto:info@janstefancic.com'>info@janstefancic.com</a> */}
        </div>
      </div>
      <Footer className="myFooterClass"/>

      <ToastContainer />
    </div>
  );
};

export default Contact;