import React, { useState, useEffect } from 'react';
import './Brand.css'; 
import Player from '@vimeo/player';

const videos = [
  "997025438",
  "1002407590",  
  "758502603",
  "758513449",
  "825302993",
  "805503766",
  "987745729",
  "959559050",
  "805497971",
  "831977046",
  "883269226",
  "830791915",
  "830792485",
  "831471425"
];

const thumbnails = [  
  `${process.env.PUBLIC_URL}/thumbnail/Vidm-te-min.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/FightClub.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/01-Krizna-jama.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/02-Pust-22.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/03-TRIMUZIJAT.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/04-KARNEVAL-Pust-2023.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/kanu-safari.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/poroka.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/07-NRP---JANCAR.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/08-Magda.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/med.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/06-VINAG-x-Pust---Zaba-HD.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/09-in-the-cold-insert.jpg`,
  `${process.env.PUBLIC_URL}/thumbnail/10-astro-insert.jpg`,
];



const Brand = () => {
  const [thumbnailVisibility, setThumbnailVisibility] = useState([]);
  const [players, setPlayers] = useState([]);


  useEffect(() => {
    setThumbnailVisibility(videos.map(() => true));
  }, []);
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  
  useEffect(() => {
    const playerElements = document.querySelectorAll('.vimeo-player');
    const players = Array.from(playerElements).map((element, index) => {
      const player = new Player(element);
      player.on('ended', () => {
        setThumbnailVisibility(thumbnailVisibility => {
          const newThumbnailVisibility = [...thumbnailVisibility];
          newThumbnailVisibility[index] = true;
          return newThumbnailVisibility;
        });
      });
      return player;
    });
    setPlayers(players);
  }, []);
  
  

  const handlePlay = (index) => {
    const newThumbnailVisibility = [...thumbnailVisibility];
    newThumbnailVisibility[index] = false;
    setThumbnailVisibility(newThumbnailVisibility);
    players[index].play();
  };

  return (
    <div className="video-grid">
      {videos.map((video, index) => (
        <div key={index} className="video-wrapper">
          <div className="video-container">
            <div 
              className={`thumbnail ${thumbnailVisibility[index] ? 'visible' : 'hidden'}`}
              style={{backgroundImage: `url(${thumbnails[index]})`}}
            >
              <div className="play-button" onClick={() => handlePlay(index)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 24 24">
                  <path d="M0 0h24v24H0z" fill="none"/>
                  <path d="M8 5v14l11-7z"/>
              </svg>
              </div>
            </div>
            <iframe 
              className="vimeo-player"
              src={`https://player.vimeo.com/video/${video}?h=9b42706ca1&title=0&byline=0&portrait=0`} 
              style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}} 
              frameBorder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              title={`Video Player ${index + 1}`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Brand;

