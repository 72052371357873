import React, { useState } from  'react';
import { NavLink } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/stef_logo.png';
import './Navbar.css';

const Menu = () => (
  <>
    <p>
      <NavLink
        className={(navData) => (navData.isActive ? 'active-link' : 'not-active')}
        to="/"
      >
        Home
      </NavLink>
    </p>
    <p>
      <NavLink
        className={(navData) => (navData.isActive ? 'active-link' : 'not-active')}
        to="/bio"
      >
        Bio
      </NavLink>
    </p>
    <p>
      <NavLink
        className={(navData) => (navData.isActive ? 'active-link' : 'not-active')}
        to="/contact"
      >
        Contact
      </NavLink>
    </p>
  </>
);




const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className='stef__navbar'>
      <div className='stef__navbar-links'>
        <div className='stef__navbar-links_logo'>
          <a href='/'><img src={logo} alt="logo" /></a>
        </div>
        <div className='stef__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='stef__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className='stef__navbar-menu_container scale-up-center'>
            <div className='stef__navbar-menu_container-links'>
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar