// src/components/Home.js
import React from 'react';
import { Footer, Header } from '../containers';
import { Brand, Navbar } from '.';
import '../App.css';

const Home = () => {
  return (
    <div className="App">
      <div className="">
        <Navbar />
        <Header />
      </div>
      <Brand />
      <Footer />
    </div>
  );
};

export default Home;

